.PageMenu-AppBar {
    input {
        padding: 8px 15px !important;
        border-radius: 6px !important;
        transition: all .2s;

        &:focus,
        &:hover {
            background: #0d529a0d;
        }
    }
}
.PageMenu-Modal {
    .MuiDialogActions-root {
        justify-content: space-between;
    }
}
.PageInbox-Table {
    box-shadow: 0 0 0 1px #e0e0e0;
    border-radius: 10px;

    tr {
        cursor: pointer;
        text-decoration: none;
        transition: all .1s;

        * {
            transition: all .1s;
        }
        &:hover,
        &:focus-visible {
            background: rgba(50, 155, 229, 0.1411764706) !important;
            
            * {
                color: #329be5 !important;
            }
        }
    }

    .PageInbox-TableRow-unseen {
        background: rgba(161, 197, 61, 0.1215686275);

        td {
            color: #465d08;
        }
    }
}