.PagePromotions-Table-Status {
    &-Active,
    &-Inactive {
        display: inline-block;
        padding: 1px 10px;
        border-radius: 100px;
        color: black !important;
    }
    &-Active {
        background: #94d60c;
    }
    &-Inactive {
        background: #c5d0d7;
    }
}