.PayoutTable {
    border: 1px solid #0f1f33;
    border-collapse: collapse;
    margin-top: 20px;
    
    th, td {
        border: 1px solid #0f1f33;
        border-collapse: collapse;
        padding: 3px 5px;
    }
}