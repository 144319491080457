.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: #081627;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    padding: 0 32px;
    z-index: 10;
    box-shadow: 0 2px 21px 0px #08162759;

    @media (min-width: 900px) {
        display: none;
    }

    img {
        height: 25px;
    }
}