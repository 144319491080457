.PageOrderDetails {

    &-Status {
        height: 10px;
        width: 20px;
        background: #ff7c00;
        display: inline-block;
        border-radius: 100px;
        margin-right: 10px;

        &--pending {
            background: #329be5;
        }
        &--in_progress {
            background: #FFCB00;
        }
        &--finished {
            background: #a6e82c;
        }
        &--canceled {
            background: #e82c2c;
        }
        &--canceled_payment {
            background: #e82c2c;
        }
    }

    &-OrderProducts {
        padding: 0;

        p {
            margin: 0;
        }

        &-Item {
            display: flex;
            width: 100%;
            border: 1px solid #b5c2c9;
            padding: 10px;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 15px;
            background: #f6f6f6;
            border-radius: 5px;

            &--has-extras {
                border-radius: 5px 5px 0 0;
            }
        }
        &-ItemDetails-Category {
            font-size: 15px;
            font-weight: bold;
            color: #101B30;
            opacity: 0.8;
        }
        &-ItemDetails-Name {
            font-size: 18px;
            font-weight: bold;
            color: #101B30;
        }
        &-ItemPrice {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            &-Value {
                font-size: 18px;
                font-weight: bold;
                color: #101B30;
            }
        }
        &-ExtraIngredient {
            display: flex;
            width: 100%;
            border: 1px solid #b5c2c9;
            border-top: none;
            padding: 5px 10px 5px 15px;
            flex-direction: row;
            justify-content: space-between;
            background: #f6f6f6;

            &-Name,
            &-Price {
                font-size: 15px;
                font-weight: bold;
                color: #101B30;
            }
        }
    }

    &-OrderSummary {
        display: flex;
        width: 100%;
        align-items: flex-end;
        padding-top: 20px;
        border-top: 1px solid #b5c2c9;

        @media (max-width:900px) {
            align-items: normal;
            flex-direction: column;

            &-Item {
                width: 100% !important;
                margin-bottom: 10px;

            }
            &-Keys {
                text-align: left !important;
            }
            &-Keys,
            &-Values {
                border-top: 1px solid #b5c2c9 !important;
                padding-top: 10px !important;
            }
        }

        &-Item {
            width: 50%;
            float: left;

            &-Address {
                font-size: 15px;
                font-weight: 500;
                color: #101B30;
                opacity: 1;
                padding: 2px 0;
            }
        }

        &-Keys {
            width: 60%;
            float: left;
            text-align: right;
            padding-right: 10px;

            &-Key {
                font-size: 15px;
                font-weight: 500;
                color: #101B30;
                opacity: 0.8;
                padding: 2px 0;

                &-Total {
                    font-size: 16px;
                    font-weight: 600;
                    opacity: 1;
                    color: #095f9b;
                }
            }
        }
        &-Values {
            width: 40%;
            float: left;
            text-align: right;
            padding-right: 10px;

            &-Value {
                font-size: 15px;
                font-weight: 500;
                color: #101B30;
                opacity: 1;
                padding: 2px 0;

                &-Total {
                    font-size: 16px;
                    font-weight: 600;
                    opacity: 1;
                    color: #095f9b;
                }
            }

        }

        p {
            margin: 0;
        }
    }

    &-Footer {
        width: 100%;
        background: #081627;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 20px;
        flex-direction: column;

        button {
            margin: 5px 0;
            width: 100%;
        }
    }
}