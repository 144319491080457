* {
    box-sizing: border-box;
}
#root, html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', 'Roboto', sans-serif;
}

.login-container {
    width: 400px;
    height: 100%;
    float: left;
    background: #f8f8f8;
    padding: 50px 30px;
    box-shadow: 6px 0px 20px #041b3663;

    h2 {
        margin: 0;
        font-size: 28px;
        color: #08315e;
    }
    p {
        margin: 0;
        font-size: 17px;
        color: #657892;
    }

    .MuiFormControl-root {
        margin: 10px 0;

        .MuiOutlinedInput-root {
            background-color: white;
        }
    }
    .MuiButton-root {
        margin-top: 10px;
    }
}
.login-background {
    position: relative;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% - 400px);
    height: 100%;
    float: right;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #052638d9, transparent);
    }
}