.bootup-step {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
    
    &-dot {
        width: 30px;
        height: 30px;
        background: #a1c53d26;
        border-radius: 100%;
        margin-right: 10px;
        border: 2px solid #a1c53d;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &--success {
            background: #a1c53d !important;
        }
        &--error {
            border-color: #e82c2c;
            background: #e82c2c !important;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: white;
        }
    }
    div:not(.bootup-step-dot) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 10px;
    }
    &-title {
        font-size: 18px;
        font-weight: 600;
        color: #101b30;
    }
    &-subtitle {
        font-size: 16px;
        font-weight: 500;
        color: #101b30c2;
        font-style: italic;
    }
}



