.Navigation-Item-Button {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border-radius: 10px !important;
    margin: 2.5px 5px !important;

    &.active {
        background: #a1c53d1f !important;
        color: #a1c53d !important;
    }
}
.DrawerPendingOrder {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 20px;
    color: white;
    width: calc(100% - 20px);
    text-align: center;
    padding: 15px;
    margin: 0 10px;
    background: #a1c53d;
    border-radius: 15px;
    box-shadow: 0 0 0 4px #a1c53d75;
    font-size: 19px;
    font-weight: bold;
    color: #081527;
}