.PageHome-AppBar {
    input {
        padding: 8px 15px !important;
        border-radius: 6px !important;
        transition: all .2s;

        &:focus,
        &:hover {
            background: #0d529a0d;
        }
    }
}
.PageHome-NewDeliveryTimeHelper {
    background: #c9dadf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 220px;
    border-radius: 15px;
    margin-bottom: 20px;

    img {
        height: 100%;
        border-radius: 10px;
        box-shadow: 4px 5px 9px -5px #005f8a52;
    }

    div {
        padding: 20px;

        h2 {
            margin: 0;
            font-size: 26px;
            color: #0f2033;
            font-weight: 700;
        }
        p {
            margin: 10px 0 0 0;
            font-size: 19px;
            color: #0f2033;
        }
    }
}
.PageHome-Orders {
    &-Item {
        background: #f8f9f9 !important;

        &--finished,
        &--canceled,
        &--canceled_payment {
            opacity: .6;
        }

        &-Status {
            height: 10px;
            width: 20px;
            background: #ff7c00;
            display: inline-block;
            border-radius: 100px;
            margin-right: 5px;

            &--pending {
                background: #329be5;
            }
            &--in_progress {
                background: #FFCB00;
            }
            &--finished {
                background: #a6e82c;
            }
            &--canceled {
                background: #e82c2c;
            }
            &--canceled_payment {
                background: #e82c2c;
            }
        }

        &-KeyValue {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;
            border-bottom: 1px solid #dfdfdf;
            margin-top: 5px;

            &-Key {
                font-weight: 600 !important;
            }
        }
    }
    center {
        svg {
            font-size: 68px;
            margin-bottom: 20px;
            background: #cadadf;
            padding: 13px;
            border-radius: 1000px;
            color: #0e2133;
            margin-top: 35px;
        }
    }
}

.new-order-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(161 197 61 / 80%);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    h1 {
        color: #042638;
        font-size: 50px;
        text-align: center;
    }
}