.PageMenu-AppBar {
    input,
    select,
    .MuiSelect-select {
        padding: 8px 15px !important;
        border-radius: 6px !important;
        transition: all .2s;

        &:focus,
        &:hover {
            background: #0d529a0d;
        }
    }
}
.PageInboxMessage {
    &-Message {
        p {
            margin: 0;
        }
    }
    &-MessageFooter {
        background: #f5f5f5;
        padding: 10px;
        border-radius: 10px;
        margin-top: 30px;
        border: 1px solid #eaeff1;
    }
}