.PageMenu-AppBar {
    input {
        padding: 8px 15px !important;
        border-radius: 6px !important;
        transition: all .2s;

        &:focus,
        &:hover {
            background: #0d529a0d;
        }
    }
}
.PageMenu-Modal {
    .MuiDialogActions-root {
        justify-content: space-between;
    }
}
.PageMenu-Table {
    box-shadow: 0 0 0 1px #e0e0e0;
    border-radius: 10px;

    tr {
        cursor: pointer;
        text-decoration: none;
        transition: all .1s;

        * {
            transition: all .1s;
        }
        &:hover,
        &:focus-visible {
            background: rgba(50, 155, 229, 0.1411764706) !important;
            
            * {
                color: #329be5 !important;
            }
        }
    }
}
.SingleHolidayItem {
    margin-bottom: 20px;
    width: 100%;
    background: whitesmoke;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #dde6ea;

    &-Header {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        
        h2 {
            margin: 0;
            font-size: 17px;
            color: #101f33;
        }
    }
    i {
        display: inline-block;
        margin-top: 20px;
        font-size: 14px;
        color: #101f33;
        font-weight: 300;
        opacity: .7;
    }
}