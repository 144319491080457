.Non-Certified-Browser-Warning {
    left: 50%;
    position: fixed;
    bottom: 10px;
    padding: 10px;
    background: red;
    transform: translateX(-50%);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
    pointer-events: none;
    opacity: .8;
    border-radius: 8px;
    box-shadow: 0px 5px 7px #ab5757b0;
    outline: 2px solid #c70000;
    outline-offset: 2px;
    z-index: 1199;

    @media (max-width: 900px) {
        width: calc(100% - 20px) !important;
    }
}