.Assist {
    &-subtitle {
        margin-top: 0px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #101f33ab;
    }
    &-image {
        width: 250px;
        height: 190px;
        object-fit: cover;
        margin-bottom: 40px;
    }
    &-hr {
        width: 100%;
        background: #212e3d38;
        height: 1px;
        margin: 50px 0;

        &--small {
            margin: 20px 0 10px 0;
        }
    }

    &-feature {
        width: 100%;
        padding: 20px 15px;
        background: white;
        border-radius: 15px;
        border: 1px solid #d7d7d7;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &-title {
            font-size: 20px;
            font-weight: 600;
            color: #101f33;
        }
        &-image {
            width: 100%;
            height: 170px;
            object-fit: contain;
            padding-top: 20px;
            border-top: 1px solid #c6c9cc82;
        }
        &-description {
            margin: 10px 0 20px 0;
            display: block;
            font-weight: 300;
            color: #101f33d6;
            font-size: 15px;
        }
    }

    &-modal {
        &-content {
            a {
                color: #042638;
            }
        }
    }

    &-recommendation {
        background: #eaeff1;
        margin-bottom: 10px;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid #d7dcde;

        &-title {
            font-size: 17px;
            font-weight: bold;
            color: #101f33;
            display: inline-block;
            margin-bottom: 5px;
        }
        &-subtitle {
            font-size: 15px;
            color: #101f33;
            opacity: .7;
            font-weight: 300;
        }
        &-actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}